<template>
  <div>

    <b-card
      no-body
      class="saj-title mr-1"
      style=""
    >
      <div
        class="saj-title pl-2 pt-1"
        style=""
      >
        {{ $t('Project Name') }} : {{ projectTitle }}
      </div>

      <div
        class="saj-title pl-2"
        style=""
      >
        {{ $t('Project Creator') }} : {{ assignee }}
      </div>

      <b-card-body>
        <b-table
          show-empty
          :items="memberList"
          :fields="fields"
          responsive=""
          bordered
          class="mt-1 mb-1 saj-subtitle"
        >
          <!-- A custom formatted column -->
          <template #head()="data">
            <span
              class="saj-title"
              style=""
            >{{ $t(data.label) }}</span>
          </template>

          <template #empty="">
            <h4 style="text-align: center; font-style: italic">
              {{ $t('There are no records to show') }}
            </h4>
          </template>

          <template #cell(index)="data">
            {{ data.index + 1 }}
          </template>
          <template #cell(name)="data">
            {{ data.item.name }}
          </template>
          <template #cell(activity)="data">
            {{ data.item.activity }}
          </template>
          <template #cell(progress)="data">
            <div
              class="d-flex justify-content-center"
            >
              {{ data.item.progress + "%" }} </div>
          </template>
          <template #cell(remarks)="data">
            <div class="d-flex justify-content-center">
              {{ $t(data.item.remarks === null ? '-' : data.item.remarks) }}
            </div>
          </template>
          <template #cell(assigned_date)="data">
            <div class="d-flex justify-content-center">
              {{ data.item.assigned_date !== null ? DateChanged(data.item.assigned_date) : '-' }}
            </div>
          </template>

          <template #cell(due_date)="data">
            <div
              class="d-flex justify-content-center"
            >
              {{ data.item.due_date !== null ? DateChanged(data.item.due_date) : '-' }}
            </div>
          </template>
        </b-table>
      </b-card-body>
    </b-card>
    <b-card
      no-body
      class="saj-title mr-1 p-1"
      style=""
    >
      <div
        class="saj-title pl-1"
        style=""
      >
        {{ $t('My Timeline') }}
        <b-table
          show-empty
          :items="historyList"
          :fields="fields2"
          responsive=""
          bordered
          class="mt-1 mb-1 pr-1 saj-subtitle"
        >
          <template #head()="data">
            <span
              class="saj-title"
              style=""
            >{{ $t(data.label) }}</span>
          </template>

          <template #empty="">
            <h4 style="text-align: center; font-style: italic">
              {{ $t('There are no records to show') }}
            </h4>
          </template>

          <template #cell(activity)="data">
            {{ $t(data.item.activity) }}
          </template>

          <template #cell(progress)="data">
            <div class="d-flex justify-content-center">
              {{ $t(data.item.progress) + "%" }}
            </div>
          </template>

          <template #cell(remarks)="data">
            <div class="d-flex justify-content-center">
              {{ $t(data.item.remarks === null ? '-' : data.item.remarks) }}
            </div>
          </template>

          <template #cell(date)="data">
            <div
              class="d-flex justify-content-center"
            >
              {{ DateChanged(data.item.created_at) }}
            </div>
          </template>

        </b-table>
      </div>
    </b-card>
  </div>
</template>
<script>
import {
  BCard,
  BCardBody,
  BTable,
} from "bootstrap-vue"
import { mapGetters } from 'vuex'
import moment from "moment"

export default {
  components: {
    BCard,
    BCardBody,
    BTable,

  },

  data() {
    return {
      projectViewEmployee: [],
      memberList: [],
      //   memberData: null,
      fields: [
        {
          key: 'employee_number', label: 'Employee No.', thClass: 'text-center', tdClass: 'text-center',
        },
        { key: 'name', label: 'Name', thClass: 'text-center' },
        { key: 'activity', label: 'Activity', thClass: 'text-center' },
        { key: 'progress', label: 'Status', thClass: 'text-center' },
        { key: 'remarks', label: 'Remarks', thClass: 'text-center' },
        {
          key: 'assigned_date', label: 'Assigned Date', thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'due_date', label: 'Due Date', thClass: 'text-center', tdClass: 'text-center',
        },

      ],
      fields2: [
        { key: 'activity', label: 'Activity', thClass: 'text-center' },
        { key: 'progress', label: 'Status', thClass: 'text-center' },
        { key: 'remarks', label: 'Remarks', thClass: 'text-center' },
        {
          key: 'date', label: 'Date', thClass: 'text-center', tdClass: 'text-center',
        },
      ],
      assignee: null,
      historyList: null,
      allMemberEmpNo: null,
      filterId: null,
      myMemberId: null,
    }
  },
  computed: {
    ...mapGetters(['userDetails']),
    // isEnglish() {
    //   return this.$i18n.locale === 'en'
    // },
  },
  mounted() {
    this.getProjManagement()
    // this.viewHistory()
  },
  beforeMount() {
    this.id = this.$route.params.id
    this.projectTitle = this.$route.params.projectTitle
  },
  methods: {
    DateChanged(date) {
      const newDay = moment(date).format("D-MM-yyyy")
      return newDay
    },
    getProjManagement() {
      this.$axios.get(`${this.$baseUrl}/getProjectManagement?user_id=${this.userDetails.user_id}`).then(response => {
        // console.log('project view member', response)
        const project = response.data.data.project_management_view
        if (project.length !== 0){
          this.projectViewEmployee = project.filter(x => (x.id === this.id))
          this.memberList = this.projectViewEmployee[0].employee
          this.assignee = this.projectViewEmployee[0].assignee
          this.allMemberEmpNo = this.projectViewEmployee[0].employee.map(x => ({
            employee_number: x.employee_number,
            id: x.id,
          }))
          this.filterId = this.allMemberEmpNo.filter(x => (x.employee_number === this.userDetails.employee_number))
          this.myMemberId = this.filterId[0].id
          // this.myMemberId = this.allMemberEmpNo.filter(x => (x.employee_number === this.userDetails.employee_number ))
          // console.log('project', this.projectViewEmployee)
          // console.log('member emp no', this.allMemberEmpNo)
          // console.log('my timeline', this.myMemberId[0].id)
          // console.log('idddddd', this.myMemberId)
          this.viewHistory()
        }
      })
    },
    viewHistory(){
      this.$axios.get(`${this.$baseUrl}/getProjectManagementHistory?id=${this.myMemberId}`).then(response => {
        this.historyList = response.data.data
        // console.log(this.historyList)
      })
    },
  },

}
</script>
